import request from '@/utils/request'


// 查询任务-配置列表
export function listTaskDeploy(query) {
  return request({
    url: '/platform/task-deploy/list',
    method: 'get',
    params: query
  })
}

// 查询任务-配置分页
export function pageTaskDeploy(query) {
  return request({
    url: '/platform/task-deploy/page',
    method: 'get',
    params: query
  })
}

// 查询任务-配置详细
export function getTaskDeploy(data) {
  return request({
    url: '/platform/task-deploy/detail',
    method: 'get',
    params: data
  })
}

// 新增任务-配置
export function addTaskDeploy(data) {
  return request({
    url: '/platform/task-deploy/add',
    method: 'post',
    data: data
  })
}

// 修改任务-配置
export function updateTaskDeploy(data) {
  return request({
    url: '/platform/task-deploy/edit',
    method: 'post',
    data: data
  })
}

// 删除任务-配置
export function delTaskDeploy(data) {
  return request({
    url: '/platform/task-deploy/delete',
    method: 'post',
    data: data
  })
}
